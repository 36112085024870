import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageDetails from '../components/pageDetails'

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.page.frontmatter.title}
        seoTitle={data.page.frontmatter.seo_title}
        description={data.page.frontmatter.seo_description}
      />
      <PageDetails data={data.page} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo_title
        seo_description
      }
    }
  }
`
