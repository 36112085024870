import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'

import styles from './pageDetails.module.css'

const PageDetails = ({ data }) => {
  const { frontmatter, html } = data

  return (
    <div className={classNames('bg-paint-top-blue', styles.root)}>
      <div className={styles.wrapper}>
        <h1
          className={classNames('title', styles.title)}
          dangerouslySetInnerHTML={{
            __html: frontmatter.title.toString().replace('\n', '<br />'),
          }}
        />
        <MarkdownWithHtml
          source={html}
          escapeHtml={false}
          className={styles.content}
        />
      </div>
    </div>
  )
}

PageDetails.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageDetails
